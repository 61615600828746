
@import '~@tabler/core/src/scss/tabler.scss';
@import '~@tabler/core/dist/css/tabler-flags.css';
@import '~@tabler/core/dist/css/tabler-payments.css';
@import '~@tabler/core/src/scss/tabler-vendors.scss';
@import 'node_modules/@tabler/icons/iconfont/tabler-icons.scss';
// @import '~@tabler/core/src/scss/demo.scss';
// @import '~tom-select/src/scss/tom-select.bootstrap5.scss';
// @import "~bootstrap/scss/bootstrap";
@import '~animate.css';
.theme-dark{
  --tblr-bg-surface: #172030;
}
.dis-aa{
  image-rendering: optimizeSpeed;             /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges;          /* Firefox                        */
  image-rendering: -o-crisp-edges;            /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor; 
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 200px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}


@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}


.home-marquee{
  width:100%;
  overflow:hidden;
  position:relative;
  margin:0;
}
.edge{
  left:0;
  right:0;
  z-index: 10;
  top:0;
  bottom:0;
  position:absolute;
  height:100%;
  display:block;
}
.edge:before{
  content:'';
  position:absolute;
  left:0;
  background:-webkit-linear-gradient(left, #cfd3de 10%, rgba(0,0,0,0) 100%);
  width:25%;
  height:100%;
}
.edge:after{
  content:'';
  position:absolute;
  right:0;
  background:-webkit-linear-gradient(right, #dce2ee 5%, rgba(0,0,0,0) 100%);
  width:25%;
  height:100%;
}
.home-marquee ul{
  overflow:hidden;
  width:1000%;
  margin:0;
}
.home-marquee li{
  list-style:none;
  display:inline-block;
  padding:0 5px;
}

@media (min-width: 992px){
  .navbar-expand-lg.navbar-vertical ~ .navbar, .navbar-expand-lg.navbar-vertical ~ .page-wrapper {
    margin-left: 5rem;
  }
}
@media (min-width: 992px){
  .navbar-vertical.navbar-expand-lg {
      width: 5rem;
  }
}